import React, { useEffect, useState, useRef } from 'react'
import { message, Modal, Spin } from 'antd'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'
import { strapiURL } from '../../config'
import { getDescription } from '../../functions'
import useGMT from '../../hooks/useGTM'

const ApplyJobModal = ({
  currentJob,
  jobEmployer,
  setApplyJob,
  userProfile,
  setSuccessModal,
  loadUserProfile,
}) => {
  const [fullContent, setFullContent] = useState(false)
  const [resume, setResume] = useState(null)
  const [coverLetterName, setCoverLetterName] = useState('')
  const [coverLetterText, setCoverLettertext] = useState(null)
  const [newResume, setNewResume] = useState(userProfile?.defaultResume)
  const [coverLetterLoading, setCoverLetterLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const textAreaRef = useRef(null)
  let ReactQuill
  if (typeof window !== 'undefined') {
    ReactQuill = require('react-quill')
  }

  const { pushApplyInternalJobEvent, pushUploadResumeEvent } = useGMT()

  useEffect(() => {
    if (currentJob) {
      if (userProfile && userProfile?.defaultCoverLetter) {
        setCoverLettertext(userProfile.defaultCoverLetter.text)
      } else {
        setCoverLettertext('')
      }
    }
  }, [currentJob, userProfile])

  const updateCoverLetterHandler = () => {
    setCoverLetterLoading(true)
    let data = {
      candidate_profile: userProfile.id,
      cover_letter_text: coverLetterText,
      username: userProfile?.username,
    }
    var bodyFormData = new FormData()
    bodyFormData.append('data', JSON.stringify(data))

    axios({
      method: 'post',
      url: strapiURL + `/candidate-profiles/update/general`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        loadUserProfile()
        message.success('Updated')
        setCoverLetterLoading(false)
      })
      .catch(function (response) {
        message.error('Something went wrong.')
        setCoverLetterLoading(false)
      })
  }

  const fileAddHandler = e => {
    let file = e?.target?.files[0]
    if (file) {
      pushUploadResumeEvent()
      setNewResume(file)
    }
    setResume(null)
  }

  const closeHandler = () => {
    setApplyJob(null)
    setResume(null)
    setCoverLettertext(null)
    setNewResume(null)
    setCoverLetterLoading(false)
  }

  const submitHandler = () => {
    var bodyFormData = new FormData()
    if (!newResume && !resume) {
      message.error('Please attach a resume.')
      return
    }

    pushApplyInternalJobEvent({
      jobid: currentJob.strapiId || currentJob.id,
      jobIndustry: currentJob.product_category || '',
      jobFunction: currentJob.primary_function || '',
      jobPostingDate: new Date(currentJob.published_at).toDateString(),
      jobLocation: currentJob.location,
    })

    setSubmitLoading(true)

    let data = {
      job: currentJob.strapiId || currentJob.id,
      candidate_profile: userProfile?.id,
      appliedAt: new Date(),
      status: 'Active',
      cover_letter_text: coverLetterText,
    }
    if (newResume) {
      bodyFormData.append('files.file', newResume)
    }
    bodyFormData.append('data', JSON.stringify(data))
    axios({
      method: 'post',
      url: strapiURL + `/applications`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        closeHandler()
        setSubmitLoading(false)
        setSuccessModal(true)
      })
      .catch(function (error) {
        console.log('THIS IS A TEST CONSOLE FOR JOB EXPIRY')
        error.response.data.message[0].error
          ? message.error(error.response.data.message[0].error)
          : message.error('Something went wrong.')
        setSubmitLoading(false)
      })
  }

  return (
    <Modal
      width={1200}
      footer={null}
      onCancel={closeHandler}
      visible={currentJob}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="modal-head bg-voyage py-5 px-5 lg:px-16 rounded-tl rounded-tr">
        <p className="font-sans font-bold text-lg text-white mb-0">Apply</p>
      </div>
      <div className="bg-white rounded-bl rounded-br pb-8">
        <div className="w-full">
          <div className="bg-oppertunityCardOneBg px-5 lg:px-16">
            <div className="flex items-center border-b border-bgSecondaryButton py-4">
              <img
                src="/icons/icon-job-apply.svg"
                alt="Job apply icon"
                className="mb-0"
              />
              <h1 className="ml-3 font-playfair text-xl font-normal mb-0">
                {currentJob?.employer}
              </h1>
            </div>
            <div className="pt-4">
              <div className="flex items-center">
                <div className="h-14 w-14 rounded shadow flex items-center justify-center">
                  <div
                    className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${
                        currentJob?.employerDetails?.image?.url ||
                        jobEmployer?.image?.url ||
                        '/icons/icon-medical.png'
                      })`,
                    }}
                  ></div>
                </div>
                <div className="ml-3">
                  <p className="font-semibold font-sans text-xl mb-1">
                    {' '}
                    {currentJob?.title}
                  </p>
                  <div className="flex items-center">
                    <img src="/icons/icon-location-pin.svg" className="mb-0" />
                    <span className="text-last text-location leading-0 ml-1">
                      {currentJob?.location}
                    </span>
                  </div>
                </div>
              </div>
              <p className="font-semibold font-sans text-lg mb-1 mt-2">
                Description
              </p>
              {fullContent ? (
                <div
                  className="font-sans text-sm overflow-hidden"
                  dangerouslySetInnerHTML={{ __html: currentJob?.description }}
                ></div>
              ) : (
                <div
                  className="font-sans text-sm overflow-hidden"
                  dangerouslySetInnerHTML={{
                    __html: currentJob?.description
                      ?.substring(0, 500)
                      ?.concat('...'),
                  }}
                ></div>
              )}
              <div className="pb-4">
                {!fullContent ? (
                  <span
                    onClick={() => setFullContent(true)}
                    className="text-sm text-voyage underline cursor-pointer"
                  >
                    View More
                  </span>
                ) : (
                  <span
                    onClick={() => setFullContent(false)}
                    className="text-sm text-voyage underline cursor-pointer"
                  >
                    View Less
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="px-5 lg:px-16 pt-8">
            <div className="flex items-center border-b border-bgSecondaryButton py-4">
              <img
                src="/icons/icon-application.svg"
                alt="Job apply icon"
                className="mb-0"
              />

              <h1 className="ml-3 font-playfair text-xl font-normal mb-0">
                Your Application
              </h1>
            </div>
            <div className="pt-8 theme-form-item">
              <p className="font-semibold font-sans text-lg mb-2">
                Your Cover Letter
              </p>
              {typeof window !== 'undefined' && (
                <ReactQuill
                  className="quill-editor"
                  theme="snow"
                  value={coverLetterText}
                  onChange={val => setCoverLettertext(getDescription(val))}
                />
              )}
              <div className="pt-3">
                {!userProfile?.defaultCoverLetter && coverLetterText ? (
                  <span
                    onClick={() => updateCoverLetterHandler()}
                    className="inline-block text-base text-voyage underline mb-3 cursor-pointer"
                  >
                    Save as my Default{' '}
                    {coverLetterLoading ? (
                      <Spin className="ml-2" size="small" />
                    ) : (
                      <div />
                    )}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {userProfile?.defaultResume ? (
              <div className="">
                <p className="font-semibold font-sans text-xl mb-1">
                  Select a Resume
                </p>
                <div className="flex item-center mt-2">
                  <div
                    onClick={() => {
                      if (resume?.id === userProfile?.defaultResume?.id) {
                        setResume(null)
                      } else {
                        setResume(userProfile.defaultResume)
                      }
                      setNewResume(null)
                    }}
                    className={`singleCv ${
                      resume?.id === userProfile?.defaultResume?.id
                        ? 'bg-searchBorder'
                        : 'bg-gray-100'
                    } flex items-center px-3 py-1 mr-2 cursor-pointer rounded `}
                  >
                    <span>{userProfile.defaultResume.name}</span>
                    {resume?.id === userProfile?.defaultResume?.id ? (
                      <img
                        src="/icons/icon-cv-checked.svg"
                        alt="Icon checked"
                        className="mb-0 ml-2"
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}

            <div className="mt-5">
              <p className="font-semibold font-sans text-lg mb-1">
                Attach a New Resume
              </p>
              <p className="font-sans font-semibold text-base">
                Click "Browse” to Select a file from your computer then click
                ”Attach”to Complete the file transfer.
              </p>
              <div
                className={`bg-fileBg border border-voyage border-dashed rounded cursor-pointer relative py-16 flex items-center justify-center`}
              >
                {!resume && !newResume && (
                  <input
                    onChange={e => fileAddHandler(e)}
                    disabled={resume}
                    type="file"
                    accept=".pdf , .doc , .docx"
                    className="opacity-0 w-full absolute inset-0 cursor-pointer"
                  />
                )}

                {resume || newResume ? (
                  <p className="font-sans text-sm font-semibold mb-0">
                    {newResume ? newResume.name : resume ? resume.name : ''}
                    &nbsp;
                    <span
                      onClick={() => {
                        setResume(null)
                        setNewResume(null)
                      }}
                      className="underline text-voyage text-tagSize"
                    >
                      Remove
                    </span>{' '}
                  </p>
                ) : (
                  <p className="font-sans text-base font-semibold mb-0">
                    Drag or{' '}
                    <span className="underline text-voyage">Upload Resume</span>{' '}
                    file
                  </p>
                )}
              </div>
            </div>

            <div className="flex items-center mt-8">
              <button
                onClick={() => closeHandler()}
                className="bg-bgSecondaryButton py-3 px-8 text-voyage font-bold rounded text-last font-sans mr-2"
              >
                Cancel
              </button>
              <button
                onClick={() => submitHandler()}
                className="bg-voyage py-3 px-8 font-sans text-white text-last font-bold rounded mr-2"
              >
                {submitLoading ? 'Submitting...' : 'Submit Application'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ApplyJobModal
