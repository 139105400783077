import React, { useState } from 'react'
import { Modal } from 'antd'
import axios from 'axios'
import { strapiURL } from '../../config'

const CandidatePermissionModal = ({ visible, onClose, user }) => {
  const [checkoutLoading, setCheckoutLoading] = useState(false)

  const handlePurchase = () => {
    setCheckoutLoading(true)
    // pushCancelSubscriptionEvent(products.subscriptionPurchased?.price?.nickname)

    axios
      .post(strapiURL + '/stripe/session', {
        customer_id: user?.customer_id,
        redirect_url: `${
          typeof window !== 'undefined' && window.location.origin
        }/thankyou`,
        is_employer: false,
      })
      .then(res => {
        setCheckoutLoading(false)
        if (typeof window !== 'undefined') {
          window.history.replaceState(
            '',
            '',
            `${window.location.origin}/thankyou/`
          )
          window.location.href = res?.data?.session_url
        }
        // navigate(res?.data?.session_url)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Modal
      width={600}
      footer={null}
      visible={visible}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="bg-white rounded-br px-4 pt-4 pb-8 flex flex-col items-center rounded-md">
        <p className="font-sans text-center mb-0 text-voyage font-bold text-lg mt-2">
        You are currently on the Searchable Profile Only plan. If you want to apply for jobs, please upgrade to a standard Monthly, Quarterly, or Bi-annual plan.
        </p>

        <div className="flex items-center justify-center mt-6 w-11/12 lg:w-[55%] mx-auto">
          <button
            onClick={handlePurchase}
            className="text-white py-4 px-10 bg-voyage cursor-pointer font-sans font-bold rounded mr-8"
          >
            {checkoutLoading ? 'Purchasing...' : 'Purchase'}
          </button>

          <button
            onClick={onClose}
            className="bg-bgSecondaryButton text-voyage sm:w-auto text-sm font-bold font-sans py-4 px-10 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CandidatePermissionModal
