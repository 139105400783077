import React from 'react'
import { Modal } from 'antd'

const ApplySuccessModal = ({ modalOpen }) => {
  const CloseModal = () => {
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
  }
  return (
    <Modal
      width={600}
      footer={null}
      visible={modalOpen}
      onCancel={CloseModal}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="bg-white rounded-bl rounded-br px-4 pt-4 pb-8">
        <div className="flex justify-end">
          <img
            onClick={() => CloseModal()}
            src="/icons/icon-close-modal.svg"
            className="w-4 cursor-pointer"
          />
        </div>
        <p className="font-sans text-center mb-0 text-voyage font-bold text-lg mt-2">
          You've successfully submitted your application.
        </p>
      </div>
    </Modal>
  )
}
export default ApplySuccessModal
