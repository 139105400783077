import React, { useEffect, useState } from 'react'
import { Collapse } from 'antd'
import { Link } from 'gatsby'
import axios from 'axios'
import { strapiURL } from '../../../config'
import { valuesSetHandler } from '../../../data'
import { Form } from 'antd'
import CandidateLoginModal from '../../Modals/candidateLogin'
import CandidateSignUpModal from '../../Modals/candidateSignup'
const CompareApplicants = ({
  slug,
  user,
  textClass,
  hideLoginModal,
  currentJob,
  memberDetails,
}) => {
  const [rankingData, setRankingData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [signupModal, setSignUpModal] = useState(false)
  const [loginForm] = Form.useForm()
  const [signUpForm] = Form.useForm()
  const [expData, setExpData] = useState([])
  const [educationData, setEducationData] = useState([])
  const [salaryData, setSalaryData] = useState([])

  const { Panel } = Collapse
  useEffect(() => {
    if (currentJob) {
      axios.get(strapiURL + `/jobs/ranking/${currentJob}`).then(res => {
        let copyEducationData = [
          {
            title: 'Doctorate',
            value: valuesSetHandler('Doctorate').toUpperCase(),
            canidates: 0,
          },
          {
            title: 'Masters',
            value: valuesSetHandler('Masters').toUpperCase(),
            canidates: 0,
          },
          {
            title: 'Bachelors',
            value: valuesSetHandler('Bachelors').toUpperCase(),
            canidates: 0,
          },
          {
            title: 'Associate',
            value: valuesSetHandler('Associate').toUpperCase(),
            canidates: 0,
          },
          {
            title: 'High School',
            value: 'High School'.toUpperCase(),
            canidates: 0,
          },
          {
            title: 'Other',
            value: valuesSetHandler('Other').toUpperCase(),
            canidates: 0,
          },
        ]
        let copyExp = [
          {
            title: '10+ years',
            value: 10,
            canidates: 0,
          },
          {
            title: '6-10 years',
            value: 6,
            canidates: 0,
          },
          {
            title: '3-5 years',
            value: 3,
            canidates: 0,
          },
          {
            title: '0-2 years',
            value: 0,
            canidates: 0,
          },
          {
            title: 'Unknown',
            value: valuesSetHandler('Unknown').toUpperCase(),
            canidates: 0,
          },
        ]
        let copySalaryData = [
          {
            title: 'Unknown',
            canidates: 0,
          },
          {
            title: '$40k - $80k',
            canidates: 0,
            minValue: 40000,
            maxValue: 80000,
            canidates: 0,
          },
          {
            title: '$80k - $100k',
            canidates: 0,
            minValue: 80001,
            maxValue: 100000,
            canidates: 0,
          },
          {
            title: '$100k - $130k',
            canidates: 0,
            minValue: 100001,
            maxValue: 130000,
            canidates: 0,
          },
          {
            title: '$130k - $160k',
            canidates: 0,
            minValue: 130001,
            maxValue: 160000,
            canidates: 0,
          },
          {
            title: '$160k - $200k',
            canidates: 0,
            minValue: 160001,
            maxValue: 200000,
            canidates: 0,
          },
          {
            title: '$200k - $300k',
            canidates: 0,
            minValue: 200001,
            maxValue: 300000,
            canidates: 0,
          },
          {
            title: '+$300k',
            canidates: 0,
          },
        ]
        setRankingData(res.data)
        res?.data?.forEach(application => {
          // Settng up years of exp
          if (!application.general_sales_experience) {
            let lasIndex = copyExp?.length - 1
            copyExp[lasIndex].canidates = copyExp[lasIndex].canidates + 1
            copyExp[lasIndex].percentage =
              (copyExp[lasIndex].canidates / res?.data?.length) * 100
          } else {
            for (let i = 0; i < copyExp?.length; i++) {
              let totalYearsOfExperience = application.general_sales_experience
              if (totalYearsOfExperience >= copyExp[i].value) {
                copyExp[i].canidates = copyExp[i].canidates + 1
                copyExp[i].percentage =
                  (copyExp[i].canidates / res?.data?.length) * 100
                break
              }
            }
          }

          // Setting up years of education
          if (
            !memberDetails?.education_level ||
            !application?.education_level
          ) {
            let lasIndex = copyEducationData?.length - 1
            copyEducationData[lasIndex].canidates =
              copyEducationData[lasIndex].canidates + 1
            copyEducationData[lasIndex].percentage =
              (copyEducationData[lasIndex].canidates / res?.data?.length) * 100
          } else {
            for (let i = 0; i < copyEducationData?.length; i++) {
              if (
                application?.education_level
                  ?.toUpperCase()
                  ?.indexOf(copyEducationData[i].value) !== -1
              ) {
                copyEducationData[i].canidates =
                  copyEducationData[i].canidates + 1
                copyEducationData[i].percentage =
                  (copyEducationData[i].canidates / res?.data?.length) * 100
                break
              }
            }
          }

          // Setting up salary of education
          let currentSalary = application.compensation_max
          let lasIndex = copySalaryData?.length - 1
          if (
            memberDetails?.statIndicators?.progress !== 100 ||
            !currentSalary ||
            currentSalary < 40000
          ) {
            copySalaryData[0].canidates = copySalaryData[0].canidates + 1
            copySalaryData[0].percentage =
              (copySalaryData[0].canidates / res?.data?.length) * 100
          } else if (currentSalary > 300000) {
            copySalaryData[lasIndex].canidates =
              copySalaryData[lasIndex].canidates + 1
            copySalaryData[lasIndex].percentage =
              (copySalaryData[lasIndex].canidates / res?.data?.length) * 100
          } else {
            for (let i = 0; i < copySalaryData?.length; i++) {
              let minValue = copySalaryData[i]?.minValue
              let maxValue = copySalaryData[i]?.maxValue
              if (
                currentSalary === minValue ||
                currentSalary === maxValue ||
                (currentSalary > minValue && currentSalary < maxValue)
              ) {
                copySalaryData[i].canidates = copySalaryData[i].canidates + 1
                copySalaryData[i].percentage =
                  (copySalaryData[i].canidates / res?.data?.length) * 100
                break
              }
            }
          }
        })
        setExpData(copyExp)
        setEducationData(copyEducationData)
        setSalaryData(copySalaryData)
      })
    }
  }, [currentJob, memberDetails])

  const closeLoginModal = () => {
    setLoading(false)
    setLoginModal(false)
    loginForm.resetFields()
  }
  const closeSignUpModal = () => {
    setLoading(false)
    setSignUpModal(false)
    signUpForm.resetFields()
  }
  const openLoginModal = () => {
    closeSignUpModal()
    setLoginModal(true)
  }
  const openSignupModal = () => {
    closeLoginModal()
    setSignUpModal(true)
  }
  return (
    <>
      <div>
        <h1 className={`font-bold font-sans text-[20px] mb-0 ${textClass}`}>
          See how you compare
        </h1>
        <p className={`text-ten font-sans ${textClass}`}>
          {rankingData?.length || 0} members applied to this job
        </p>
      </div>
      <Collapse
        accordion
        className="w-full compare-data-collapse"
        defaultActiveKey={['1']}
        expandIconPosition="right"
        expandIcon={({ isActive }) => {
          if (isActive) {
            return <img src="/icons/icon-collapse-minus.svg" />
          } else {
            return <img src="/icons/icon-collapse-plus.svg" />
          }
        }}
      >
        <Panel header="Years of Experience" key="1">
          <>
            {expData &&
              expData.map((exp, expIndex) => {
                return (
                  <div className="flex justify-between mb-1" key={expIndex}>
                    <p className="text-ten mb-0 pr-1 w-1/6 text-right font-sans">
                      {exp.title}
                    </p>
                    <div className="w-2/3 bg-progress h-2 rounded">
                      <div
                        className="h-2 rounded bg-voyage"
                        style={{
                          width: exp.percentage ? exp.percentage + '%' : '0%',
                        }}
                      />
                    </div>
                    <p className="text-ten mb-0 pl-1 font-sans">
                      {exp.canidates}
                    </p>
                  </div>
                )
              })}
          </>
        </Panel>
        <Panel header="Annual Salary" key="2">
          {salaryData &&
            salaryData.map((sal, salIndex) => {
              return (
                <div className="flex justify-between mb-1" key={salIndex}>
                  <p className="text-ten mb-0 pr-1 w-22% text-right font-sans">
                    {sal.title}
                  </p>
                  <div className="w-2/3 bg-progress h-2 rounded">
                    <div
                      className="h-2 rounded bg-voyage"
                      style={{
                        width: sal.percentage ? sal.percentage + '%' : '0%',
                      }}
                    />
                  </div>
                  <p className="text-ten mb-0 pl-1 font-sans">
                    {sal.canidates}
                  </p>
                </div>
              )
            })}
        </Panel>
        <Panel header="Education Level" key="3">
          {educationData &&
            educationData.map((edu, eduIndex) => {
              return (
                <div className="flex justify-between mb-1" key={eduIndex}>
                  <p className="text-ten mb-0 pr-1 w-1/6 text-right font-sans">
                    {edu.title}
                  </p>
                  <div className="w-2/3 bg-progress h-2 rounded">
                    <div
                      className="h-2 rounded bg-voyage"
                      style={{
                        width: edu.percentage ? edu.percentage + '%' : '0%',
                      }}
                    />
                  </div>
                  <p className="text-ten mb-0 pl-1 font-sans">
                    {' '}
                    {edu.canidates}
                  </p>
                </div>
              )
            })}
        </Panel>
      </Collapse>
      {!user && !hideLoginModal ? (
        <div className="py-4 flex flex-col items-start w-full">
          <p className="font-sans text-base font-bold mb-2">Members</p>
          <p className="font-sans font-sm">
            Login to view and apply to this job
          </p>

          <button
            onClick={() => openLoginModal()}
            className="bg-voyage py-2 px-10 font-sans text-white text-[14px] font-semibold rounded mr-2"
          >
            Login
          </button>
        </div>
      ) : (
        <div />
      )}
      {!user && !hideLoginModal ? (
        <div className="py-4 flex flex-col items-start w-full">
          <p className="font-sans text-base font-bold mb-2">
            Not a Member Yet?
          </p>
          <p className="font-sans font-sm">
            Become a Member to view and apply to this job
          </p>
          <Link to="/join-now" className="w-full">
            <button className="text-white py-2 px-10 bg-button cursor-pointer font-sans font-semibold rounded text-[13px]">
              Sign Me Up
            </button>
          </Link>
        </div>
      ) : (
        <div />
      )}
      <CandidateLoginModal
        form={loginForm}
        loading={loading}
        setLoading={setLoading}
        closeHandler={closeLoginModal}
        modalOpen={loginModal}
        openSignupModal={openSignupModal}
      />
      <CandidateSignUpModal
        form={signUpForm}
        loading={loading}
        setLoading={setLoading}
        closeHandler={closeSignUpModal}
        modalOpen={signupModal}
      />
    </>
  )
}
export default CompareApplicants
