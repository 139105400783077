import React from 'react'
import './style.css'

const JobDescription = ({ description }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></div>
    </>
  )
}
export default JobDescription
